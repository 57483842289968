.terminal-console-display {

 ::-webkit-scrollbar {
  width: 0.18rem;
  height: 0.18rem;
 }

::-webkit-scrollbar-track {
 background: black;
}

::-webkit-scrollbar-thumb {
 background: gray;
 border-radius: 2rem;
}

}

