.p0 {
  padding: 0 !important;
}

.pb1 {
  padding-bottom: 1rem;
}


.pb2 {
  padding-bottom: 2rem;
}

.pb4 {
  padding-bottom: 4rem;
}

.pb8 {
  padding-bottom: 8rem;
}

.mb1 {
  margin-bottom: 1rem;
}

.mb2 {
  margin-bottom: 2rem;
}

.p-small {
  padding: 0.4rem;
}

.cursor-pointer {
  cursor: pointer !important;
}

pre {
  white-space: pre-wrap;
  word-break: break-word;
  font-family: Barlow;
}

.highlight {
  background-color: #554d4d; /* Light gray background color */
  padding: 0.2rem; /* Add some padding for better readability */
  border-radius: 0.4rem; /* Add rounded corners for a softer look (optional) */
  color: white;
  font-weight: 500 !important;
}
