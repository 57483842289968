.navbar-style {
  background-color: #6E2E2E;
}

.navbar-brand {
  color: white;
  font-size: 28px;
  font-family: Lato;
  font-weight:900;
}

.navbar-brand:hover {
  color: gray!important;
}

.navbar-link {
  color: white!important;
  font-size: 1.2rem;
}

.navbar-link:hover {
  color: gray!important;
} 

.container-style {
  padding-top: 1rem;
  border-bottom: 2px solid silver;
}

.project-container-style {
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.header-text-mini {
  font-family: Barlow;
  font-size: 1.5rem;
  font-weight: 400;
  font-style: normal;
  color: #EE5252!important;
}

.header-text {
  font-family: Barlow;
  font-size: 1.6rem;
  font-weight: 900;
  font-style: italic;
  color: white;

}

.header-text-lg {
  font-family: Barlow;
  font-size: 2.5rem;
  font-weight: 900;
  color: #EE5252!important;
}

.header-padding  {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.row-padding {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.5rem ;
  padding-bottom: 0.5rem;
}

.tech-stack-row {
  margin: 1.4rem;
}

.text-style-small {
  font-size: 1rem;
}

.text-style {
  font-size: 1.2rem;
  color: white;
}

.text-style-lg-italic {
  font-size: 1.3rem;
  font-style: italic;
}

.text-style-header-JS {
  font-size: 1.8rem;
  background: -webkit-linear-gradient(yellow, gray);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-style-header-Py {
  font-size: 1.8rem;
  background: -webkit-linear-gradient(yellow, blue);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-style-header-AWS {
  font-size: 1.8rem;
  background: -webkit-linear-gradient(#fa7a08, gray);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.project-outer-card {
  border: none;
  background-color: #212529;
}

.project-outer-card:hover {
  cursor: pointer;
}
.project-outer-card:hover .project-card-text{
  cursor: pointer!important;
}

.project-inner-card {
  border-radius: 1.2rem;
  flex: 1;
  margin: 16px;
  transition: 0.3s;
  overflow: hidden;
  background-color: #212529;  
  min-height: 25rem;
  max-height: 25rem;
}


.project-inner-card:hover {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.project-card-img {
  width: 12rem;
  height: 12rem;
  align-self: center;
}

.project-card-title {
  font-weight: bold;
  font-size: 1.3rem;
  transition: 0.4s;
}

.project-inner-card:hover .project-card-title {
  color: #EE5252;
}

.project-card-body {
  border-top: 0.2px solid silver;
}

.project-card-text {
  font-size: 1.2rem;
  padding-bottom: 10px;
}

.project-github-row {
  padding-left:4rem;
  align-items: flex-end;
  flex: 1;
  width: 100%;
}

.project-card-github {
  color: gray;
  margin-left: 10px;
  cursor: pointer;
}

.project-card-github-row {
  position: absolute; 
  bottom: 0.5rem;
  flex: 1;
  width: 100%;
}

.skills-icon-col {
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.skills-icon {
  color: silver;
  transition: 0.4s color;
}

.skills-icon-anchor {
  text-decoration: none!important;
  color: white;
  transition: 0.4s color;
}

.skills-icon:hover {
  cursor: pointer;
}


 .skills-text {
  font-family: Outfit;
  font-size: 1.2rem;
  color: #212529;
  transition: 0.4s color;
}


/* highlights the text on hover */
.skills-icon-anchor#skills-icon-anchor-python:hover .skills-text {
  color: #FAC308!important;
}
/* highlights the icon on hover */
#skills-icon-anchor-python:hover > div > .skills-icon {
  color: #FAC308!important;
}

.skills-icon-anchor#skills-icon-anchor-react-native:hover .skills-text  {
  color:  #0a3b79;
}
#skills-icon-anchor-react-native:hover > div > .skills-icon {
  color:  #0a3b79!important;
}

.skills-icon-anchor#skills-icon-anchor-expo-framework:hover .skills-text  {
  color:  darkslateblue;
}
#skills-icon-anchor-expo-framework:hover > div > .skills-icon {
  color:  darkslateblue!important;
}

.skills-icon-anchor#skills-icon-anchor-typescript:hover .skills-text  {
  color:  #007acc;
}
#skills-icon-anchor-typescript:hover > div > .skills-icon {
  color:  #007acc!important;
}

.skills-icon-anchor#skills-icon-anchor-nodejs:hover .skills-text  {
  color:  #3c873a;
}
#skills-icon-anchor-nodejs:hover > div > .skills-icon {
  color:  #3c873a!important;
}

.skills-icon-anchor#skills-icon-anchor-bash:hover .skills-text  {
  color:  darkslategray;
}
#skills-icon-anchor-bash:hover > div > .skills-icon {
  color:  darkslategray!important;
}

.skills-icon-anchor#skills-icon-anchor-linux:hover .skills-text  {
  color:  white;
}
#skills-icon-anchor-linux:hover > div > .skills-icon {
  color:  black!important;
}

.skills-icon-anchor#skills-icon-anchor-ubuntu:hover .skills-text  {
  color:  #e95420;
}
#skills-icon-anchor-ubuntu:hover > div > .skills-icon {
  color:  #e95420!important;
}

.skills-icon-anchor#skills-icon-anchor-aws:hover .skills-text  {
  color:  #ff9900;
}
#skills-icon-anchor-aws:hover > div > .skills-icon {
  color:  #ff9900!important;
}

.skills-icon-anchor#skills-icon-anchor-aws-dynamodb:hover .skills-text  {
  color: #0047ab;
}
#skills-icon-anchor-aws-dynamodb:hover > div > .skills-icon {
  color: #0047ab!important;
}

.skills-icon-anchor#skills-icon-anchor-aws-lambda:hover .skills-text  {
  color:  #8B4000;
}
#skills-icon-anchor-aws-lambda:hover > div > .skills-icon {
  color:  #8B4000!important;
}

.skills-icon-anchor#skills-icon-anchor-aws-ec2:hover .skills-text  {
  color:  orange;
}
#skills-icon-anchor-aws-ec2:hover > div > .skills-icon {
  color:  orange!important;
}

.skills-icon-anchor#skills-icon-anchor-regex:hover .skills-text  {
  color:  #800020;
}
#skills-icon-anchor-regex:hover > div > .skills-icon {
  color:  #800020!important;
}

.skills-icon-anchor#skills-icon-anchor-computer-automations:hover .skills-text  {
  color:  gray;
}
#skills-icon-anchor-computer-automations:hover > div > .skills-icon {
  color:  gray!important;
}

.skills-icon-anchor#skills-icon-anchor-computer-networking:hover .skills-text  {
  color:  brown;
}
#skills-icon-anchor-computer-networking:hover > div > .skills-icon {
  color:  brown!important;
}

.skills-icon-anchor#skills-icon-anchor-cloud-computing:hover .skills-text  {
  color:  lightskyblue;
}
#skills-icon-anchor-cloud-computing:hover > div > .skills-icon {
  color:  lightskyblue!important;
}

.skills-icon-anchor#skills-icon-anchor-json:hover .skills-text  {
  color:  darkgoldenrod;
}
#skills-icon-anchor-json:hover > div > .skills-icon {
  color:  darkgoldenrod!important;
}

.skills-icon-anchor#skills-icon-anchor-vim:hover .skills-text  {
  color: darkgreen;
}
#skills-icon-anchor-vim:hover > div > .skills-icon {
  color: darkgreen!important;
}

.skills-icon-anchor#skills-icon-anchor-wireshark:hover .skills-text  {
  color:  #0f52ba;
}
#skills-icon-anchor-wireshark:hover > div > .skills-icon {
  color:  #0f52ba!important;
}

.skills-icon-anchor#skills-icon-anchor-c-language:hover .skills-text  {
  color:  #2672F7;
}
#skills-icon-anchor-c-language:hover > div > .skills-icon {
  color:  #2672F7!important;
}





.mobile-video-player-container {
  border-width: 0.1rem;
  border-color: #6E2E2E;
  border-style: solid;
  width: 18vw;
  height: 90vh;
  border-radius: 1.5rem;
  display: flex;
  overflow: hidden;
  margin-left: 5rem;
}

.video-player-container {
  border-width: 0.1rem;
  border-color: #6E2E2E;
  border-style: solid;
  width: 100%;
  height: 90vh;
  border-radius: 1rem;
  display: flex;
  overflow: auto;
}

video.video-player {
  position: absolute; 
  left: 0; 
  top: 0;
  width: 100%; 
  height: 100%;
  object-fit: fill;
}
