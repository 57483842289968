body,
html,
#root {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: Barlow;
  width: 100%;
  height: 100%;
  /* flex: 1; */

  overflow-x: hidden;
  /* background-color: #0b121f; */
  background-color: #212529;
  color: white;
}

p:hover {
  cursor: default;
}

h1:hover {
  cursor: default;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


::-webkit-scrollbar {
  width: 0.3rem;
  height: auto;
 }

::-webkit-scrollbar-track {
 background: #212529;
}

::-webkit-scrollbar-thumb {
 background: gray;
 border-radius: 2rem;
}

/* Extra extra small devices (Like galaxy fold 300px and below) */
@media all and (max-width: 300px) {
  body,
  html,
  #root {
    font-size: 11px;
  }
}

/* Extra small devices (phones, between 400px and 600px) */
@media all and (min-width: 300px) and (max-width: 600px) {
  body,
  html,
  #root {
    font-size: 12px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media all and (min-width: 600px) {
  body,
  html,
  #root {
    font-size: 13px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media all and (min-width: 768px) {
  body,
  html,
  #root {
    font-size: 14px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media all and (min-width: 992px) {
  body,
  html,
  #root {
    font-size: 15px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media all and (min-width: 1200px) {
  body,
  html,
  #root {
    font-size: 14px;
  }
}

@media all and (min-width: 1600px) {
  body,
  html,
  #root {
    font-size: 17px;
  }


}
